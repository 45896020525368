// ==============================
// Override Variables
// ==============================

$single-link-color: #cc0000;
$global-link-hover-color: #000000;

$global-font-family: 'Noto Sans JP', '游ゴシック Medium', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ　Ｐゴシック', 'MS PGothic', sans-serif;
$header-title-font-size: 1.2rem;

$blockquote-color: #c3c5c6;
$blockquote-color-dark: #c3c5c6;