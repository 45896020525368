@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700);

.single {
    .single-title {
        font-size: 1.5rem;
    }
    .post-meta {
        font-size: .95rem;
        // @include link(true, true);
        [theme=dark] & {
            color: $global-font-secondary-color;
        }

        a, a::before, a::after {
            color: $global-font-color;
        }
        [theme=dark] & {
            color: $global-font-secondary-color-dark;
        }

        a:active, a:hover {
            color: $single-link-color;
        }
        [theme=dark] & {
            color: $single-link-color-dark;
        }
    }
    .content {
        > h2 {
            font-size: 1.4rem;
        }
        > h2 > .header-mark::before {
            content: "";
            margin-right: 0rem;
        }

        img {
            max-height: 20rem;
            padding-top: 1rem;
        }

        figure {
            img {
                width: auto;
            }
        }
    }
}

.header-title {
    font-weight: 700;
}

h1.animate__animated {
  -webkit-animation-duration: 0s !important;
  animation-duration: 0s !important;
}

.home[data-home=posts] {
    .summary {
        .single-title {
            font-size: 1.2rem;
        }
        .content {
            color: $global-font-color;
            [theme=dark] & {
                color: $global-font-color-dark;
            }
        }
    }
}

#content-404 {
    font-size: 1rem;
    line-height: normal;
    text-align: inherit;
    ins {
        text-decoration: none;
        color: $global-font-secondary-color;
    }
}

.hatenablogcard {
    position: relative;
    margin: 1rem 0;
    padding: 0 .75rem;
}